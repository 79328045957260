/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ProductClassificationIEnumerableResponse from '../model/ProductClassificationIEnumerableResponse';
import ProductIEnumerableResponse from '../model/ProductIEnumerableResponse';
import ProductStreamlined from '../model/ProductStreamlined';
import ProductStreamlinedIEnumerableResponse from '../model/ProductStreamlinedIEnumerableResponse';
import ProductStreamlinedResponse from '../model/ProductStreamlinedResponse';
import ProductTypeIEnumerableResponse from '../model/ProductTypeIEnumerableResponse';
import ProductTypeRequest from '../model/ProductTypeRequest';
import ProductTypeResponse from '../model/ProductTypeResponse';
import Response from '../model/Response';

/**
* AdminProductManagement service.
* @module api/AdminProductManagementApi
* @version v1
*/
export default class AdminProductManagementApi {

    /**
    * Constructs a new AdminProductManagementApi. 
    * @alias module:api/AdminProductManagementApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductClassificationIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductClassesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductClassesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProductClassificationIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/productClasses', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductClassificationIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminProductManagementProductClassesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductClassesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductStreamlined} opts.productStreamlined 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['productStreamlined'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/product', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductStreamlined} opts.productStreamlined 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminProductManagementProductPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductStreamlined} opts.productStreamlined 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductPutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['productStreamlined'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductPut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/product', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductStreamlined} opts.productStreamlined 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminProductManagementProductPut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductPutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} productId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductStreamlinedResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductStreamlinedProductIdGetWithHttpInfo(productId, tenantId) {
      let postBody = null;
      // verify the required parameter 'productId' is set
      if (productId === undefined || productId === null) {
        throw new Error("Missing the required parameter 'productId' when calling v1TenantTenantIdAdminAdminProductManagementProductStreamlinedProductIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductStreamlinedProductIdGet");
      }

      let pathParams = {
        'productId': productId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProductStreamlinedResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/product-streamlined/{productId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} productId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductStreamlinedResponse}
     */
    v1TenantTenantIdAdminAdminProductManagementProductStreamlinedProductIdGet(productId, tenantId) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductStreamlinedProductIdGetWithHttpInfo(productId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductTypeResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductTypeIdDeleteWithHttpInfo(id, tenantId) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling v1TenantTenantIdAdminAdminProductManagementProductTypeIdDelete");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductTypeIdDelete");
      }

      let pathParams = {
        'id': id,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProductTypeResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/productType/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} id 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductTypeResponse}
     */
    v1TenantTenantIdAdminAdminProductManagementProductTypeIdDelete(id, tenantId) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductTypeIdDeleteWithHttpInfo(id, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductTypeRequest} opts.productTypeRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductTypeResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductTypePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['productTypeRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductTypePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProductTypeResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/productType', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductTypeRequest} opts.productTypeRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductTypeResponse}
     */
    v1TenantTenantIdAdminAdminProductManagementProductTypePost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductTypePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductTypeRequest} opts.productTypeRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductTypeResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductTypePutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['productTypeRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductTypePut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProductTypeResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/productType', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductTypeRequest} opts.productTypeRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductTypeResponse}
     */
    v1TenantTenantIdAdminAdminProductManagementProductTypePut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductTypePutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductTypeIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductTypesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductTypesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProductTypeIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/productTypes', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductTypeIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminProductManagementProductTypesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductTypesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductsGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductsGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProductIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/products', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminProductManagementProductsGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductsGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductStreamlinedIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminProductManagementProductsStreamlinedGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminProductManagementProductsStreamlinedGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProductStreamlinedIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminProductManagement/products-streamlined', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductStreamlinedIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminProductManagementProductsStreamlinedGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminProductManagementProductsStreamlinedGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}

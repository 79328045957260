/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ProductClassAttributeStreamlined from './ProductClassAttributeStreamlined';

/**
 * The ProductStreamlined model module.
 * @module model/ProductStreamlined
 * @version v1
 */
class ProductStreamlined {
    /**
     * Constructs a new <code>ProductStreamlined</code>.
     * @alias module:model/ProductStreamlined
     */
    constructor() { 
        
        ProductStreamlined.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ProductStreamlined</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ProductStreamlined} obj Optional instance to populate.
     * @return {module:model/ProductStreamlined} The populated <code>ProductStreamlined</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ProductStreamlined();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('productClassId')) {
                obj['productClassId'] = ApiClient.convertToType(data['productClassId'], 'Number');
            }
            if (data.hasOwnProperty('productClassName')) {
                obj['productClassName'] = ApiClient.convertToType(data['productClassName'], 'String');
            }
            if (data.hasOwnProperty('productClassDescription')) {
                obj['productClassDescription'] = ApiClient.convertToType(data['productClassDescription'], 'String');
            }
            if (data.hasOwnProperty('productClassInternalDescription')) {
                obj['productClassInternalDescription'] = ApiClient.convertToType(data['productClassInternalDescription'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('applyMessageToWebsite')) {
                obj['applyMessageToWebsite'] = ApiClient.convertToType(data['applyMessageToWebsite'], 'Boolean');
            }
            if (data.hasOwnProperty('applyMessageToEmail')) {
                obj['applyMessageToEmail'] = ApiClient.convertToType(data['applyMessageToEmail'], 'Boolean');
            }
            if (data.hasOwnProperty('showMessageAtTop')) {
                obj['showMessageAtTop'] = ApiClient.convertToType(data['showMessageAtTop'], 'Boolean');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'Number');
            }
            if (data.hasOwnProperty('bookingTypeId')) {
                obj['bookingTypeId'] = ApiClient.convertToType(data['bookingTypeId'], 'Number');
            }
            if (data.hasOwnProperty('spotTypeIds')) {
                obj['spotTypeIds'] = ApiClient.convertToType(data['spotTypeIds'], ['Number']);
            }
            if (data.hasOwnProperty('equipmentTypeIds')) {
                obj['equipmentTypeIds'] = ApiClient.convertToType(data['equipmentTypeIds'], ['Number']);
            }
            if (data.hasOwnProperty('attributeIconIds')) {
                obj['attributeIconIds'] = ApiClient.convertToType(data['attributeIconIds'], ['Number']);
            }
            if (data.hasOwnProperty('attributes')) {
                obj['attributes'] = ApiClient.convertToType(data['attributes'], [ProductClassAttributeStreamlined]);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
ProductStreamlined.prototype['id'] = undefined;

/**
 * @member {Number} productClassId
 */
ProductStreamlined.prototype['productClassId'] = undefined;

/**
 * @member {String} productClassName
 */
ProductStreamlined.prototype['productClassName'] = undefined;

/**
 * @member {String} productClassDescription
 */
ProductStreamlined.prototype['productClassDescription'] = undefined;

/**
 * @member {String} productClassInternalDescription
 */
ProductStreamlined.prototype['productClassInternalDescription'] = undefined;

/**
 * @member {String} message
 */
ProductStreamlined.prototype['message'] = undefined;

/**
 * @member {Boolean} applyMessageToWebsite
 */
ProductStreamlined.prototype['applyMessageToWebsite'] = undefined;

/**
 * @member {Boolean} applyMessageToEmail
 */
ProductStreamlined.prototype['applyMessageToEmail'] = undefined;

/**
 * @member {Boolean} showMessageAtTop
 */
ProductStreamlined.prototype['showMessageAtTop'] = undefined;

/**
 * @member {Number} productTypeId
 */
ProductStreamlined.prototype['productTypeId'] = undefined;

/**
 * @member {Number} bookingTypeId
 */
ProductStreamlined.prototype['bookingTypeId'] = undefined;

/**
 * @member {Array.<Number>} spotTypeIds
 */
ProductStreamlined.prototype['spotTypeIds'] = undefined;

/**
 * @member {Array.<Number>} equipmentTypeIds
 */
ProductStreamlined.prototype['equipmentTypeIds'] = undefined;

/**
 * @member {Array.<Number>} attributeIconIds
 */
ProductStreamlined.prototype['attributeIconIds'] = undefined;

/**
 * @member {Array.<module:model/ProductClassAttributeStreamlined>} attributes
 */
ProductStreamlined.prototype['attributes'] = undefined;






export default ProductStreamlined;


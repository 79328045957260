import AdminProductManagementApi from "@/generatedapiclients/src/api/AdminProductManagementApi.js";

export default class AdminProductManagementService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminProductManagementApi = new AdminProductManagementApi();
  }

  async getAllStreamlinedProducts() {
    const response = await this._adminProductManagementApi.v1TenantTenantIdAdminAdminProductManagementProductsStreamlinedGet(
      this._tenantId
    );
    return response.data;
  }
  async getAllProductTypes() {
    const response = await this._adminProductManagementApi.v1TenantTenantIdAdminAdminProductManagementProductTypesGet(
      this._tenantId
    );
    return response.data;
  }
  async updateProductType(productTypeRequest) {
    const response = await this._adminProductManagementApi.v1TenantTenantIdAdminAdminProductManagementProductTypePut(
      this._tenantId,
      {
        productTypeRequest
      }
    );
    return response;
  }
  async getAllProductClasses() {
    const response = await this._adminProductManagementApi.v1TenantTenantIdAdminAdminProductManagementProductClassesGet(
      this._tenantId
    );
    return response.data;
  }
  async getProduct(productId) {
    const response = await this._adminProductManagementApi.v1TenantTenantIdAdminAdminProductManagementProductStreamlinedProductIdGet(
      productId,
      this._tenantId
    );
    return response.data;
  }

  async updateProduct(product) {
    const response = await this._adminProductManagementApi.v1TenantTenantIdAdminAdminProductManagementProductPut(
      this._tenantId,
      {
        productStreamlined: product
      }
    );
    return response;
  }
  async addProduct(product) {
    const response = await this._adminProductManagementApi.v1TenantTenantIdAdminAdminProductManagementProductPost(
      this._tenantId,
      {
        productStreamlined: product
      }
    );
    return response;
  }
}
